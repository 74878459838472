import styled from 'styled-components';

const PartnerSectionWrapper = styled.section`
  padding-top: 150px;
  ${'' /* padding-bottom: 200px; */}
  ${'' /* @media (max-width: 1440px) {
    padding-bottom: 200px;
  } */}
  @media (max-width: 990px) {
    padding-top: 150px;
    ${'' /* padding-bottom: 150px; */}
  }
  @media (max-width: 768px) {
    padding-top: 150px;
    ${'' /* padding-bottom: 125px; */}
    ${'' /* .feature__images {
      text-align: center;
      justify-content: center;
      display: flex;
    } */}
  }
  @media only screen and (max-width: 480px) {
    padding-top: 130px;
    ${'' /* padding-bottom: 100px; */}
  }
  .button__wrapper {
    margin-top: 30px;
    display: flex;
    .reusecore__button {
      border-radius: 6px;
      &:first-child {
        transition: all 0.3s ease;
        ${'' /* margin-right: 30px; */}
        @media (max-width: 990px) {
          margin-right: 0;
        }
        ${'' /* &:hover {
          box-shadow: ${({ theme }) => theme.colors.primaryBoxShadow}
        } */}
      }
      &:nth-child(2) {
        padding-left: 0;
        @media (max-width: 1100px) {
          padding-left: 15px;
        }

        @media (max-width: 480px) {
          padding-left: 0;
          padding-top: 15px;
        }
        margin-top: 15px;
        .btn-text {
          font-weight: 700;
          margin-top: 7px;
        }
        .btn-icon {
          margin-top: 6px;
          margin-left: 6px;
        }
      }
      > a {
        font-weight: 700;
        color: #fff;
      }
    }
    .btnWithoutColor {
      margin-right: 15px !important;
      padding: 5px 0px !important;
      .btn-icon {
        svg {
          width: 30px;
          height: 30px;
        }
      }
      ${'' /* .btn-text {
        padding-left: 15px;
        font-size: 15px;
        font-weight: 500 !important;
        font-family: 'Poppins', sans-serif;
      } */}
      &:hover {
        box-shadow: none !important;
      }
    }
  }
  .product__specs {
    span {
      margin-left: 10px;
    }
  }
`;

export const PrevButton = styled.div`
  position: relative;
  padding: 18px 10px;
  cursor: pointer;
  &:hover {
    span {
      background: ${({ theme }) => theme.colors.primary};
      @media (min-width: 991px) {
        width: 100px;
      }
    }
  }
  span {
    width: 18px;
    height: 2px;
    background: #d1d3de;
    display: block;
    position: relative;
    transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    &:before,
    &:after {
      content: '';
      display: block;
      height: 2px;
      border-radius: 2px;
      background: inherit;
      position: absolute;
    }
    &:before {
      transform: rotate(-45deg);
      top: -4px;
      left: 0;
      width: 10px;
    }
    &:after {
      transform: rotate(45deg);
      width: 8px;
      bottom: -6px;
      left: 1px;
    }
  }
`;

export const NextButton = styled.div`
  position: relative;
  padding: 18px 10px;
  cursor: pointer;
  &:hover {
    span {
      background: ${({ theme }) => theme.colors.primary};
      @media (min-width: 991px) {
        width: 100px;
      }
    }
  }
  span {
    width: 18px;
    height: 2px;
    background: #d1d3de;
    display: block;
    position: relative;
    transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    &:before,
    &:after {
      content: '';
      display: block;
      height: 2px;
      border-radius: 2px;
      background: inherit;
      position: absolute;
    }
    &:before {
      transform: rotate(45deg);
      top: -4px;
      right: 0;
      width: 10px;
    }
    &:after {
      transform: rotate(-45deg);
      width: 8px;
      bottom: -6px;
      right: 1px;
    }
  }
`;

export default PartnerSectionWrapper;
