import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/source/contexts/DrawerContext';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Navbar from 'common/source/containers/Navbar';

import ProductBanner from 'common/source/containers/ProductBanner';
import Footer from 'common/source/containers/Footer';
import Container from 'common/source/components/UI/Container';
// import SocialLinks from '../components/SocialLinks/SocialLinks';

import { ProductInfoWrapper } from './products.style';

import SEO from '../components/SEO';

export default class ProductTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { slug } = pageContext;
    const { product, allProducts } = data;
    const allProductsForSearch = allProducts.edges;
    const post = product.frontmatter;
    if (!post.id) {
      post.id = slug;
    }

    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO postPath={slug} postNode={product} postSEO prefix='producto' />
          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass='sticky-nav-active'>
              <DrawerProvider>
                <Navbar background='purple' />
              </DrawerProvider>
            </Sticky>
            <ProductBanner
              product={product}
              allProducts={allProductsForSearch}
            />
            <ProductInfoWrapper>
              <Container>
                <div dangerouslySetInnerHTML={{ __html: product.html }} />
              </Container>
              {/* <div className="post-meta">
              <SocialLinks postPath={slug} postNode={postNode} />
            </div> */}
            </ProductInfoWrapper>
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query ProductBySlug($slug: String!) {
    product: markdownRemark(
      frontmatter: {
        slug: { eq: $slug }
        type: { eq: "PRODUCT" }
        published: { eq: true }
      }
    ) {
      html
      timeToRead
      excerpt
      fields {
        slug
        date
      }
      frontmatter {
        title
        slug
        type
        catalog
        tags
        date
        description
        cover
        manual_pdf
        # slides
      }
    }
    allProducts: allMarkdownRemark(
      limit: 2000
      filter: {
        frontmatter: { type: { eq: "PRODUCT" }, published: { eq: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          frontmatter {
            title
            slug
            description
            thumbnail
          }
        }
      }
    }
  }
`;
