import styled from 'styled-components';

const ImageGalleryWrapper = styled.div`
  width: 100%;
  padding: 4rem;
  .image-gallery-right-nav:hover::before,
  .image-gallery-left-nav:hover::before {
    color: ${({ theme }) => theme.colors.primary};
  }
  ${'' /* .image-gallery-right-nav::before,
  .image-gallery-left-nav::before {
    text-shadow: ${({ theme }) => theme.colors.baseBoxShadow};
  } */}

  .image-gallery-thumbnail.active {
    border: 4px solid ${({ theme }) => theme.colors.primary};
  }
`;
export default ImageGalleryWrapper;
