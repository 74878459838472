import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import { Icon } from 'react-icons-kit';
import { filePdf } from 'react-icons-kit/icomoon/filePdf';
import ImageGallery from 'react-image-gallery';
// import GlideCarousel from 'common/source/components/GlideCarousel';
// import GlideSlide from 'common/source/components/GlideCarousel/glideSlide';
import SocialShare from '../SocialShare';

import Container from '../../components/UI/Container';
import FeatureBlock from '../../components/FeatureBlock';
import Breadcrumbs from '../Breadcrumbs';
import Searchbar from '../Searchbar';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGalleryWrapper from './image-gallery.styles';

// import ProductBannerWrapper, { PrevButton, NextButton } from './product-banner.style';
import ProductBannerWrapper from './product-banner.style';

// const ImageSlider = ({ images }) => {
//   const carouselOptions = {
//     type: 'carousel',
//     autoplay: 4000,
//     perView: 1,
//     gap: 5,
//     animationDuration: 800
//   };

//   return (
//     <GlideCarousel
//       carouselSelector="project-carousel"
//       options={carouselOptions}
//       // progress
//       prevButton={(
//         <PrevButton>
//           <span />
//         </PrevButton>
// )}
//       nextButton={(
//         <NextButton>
//           <span />
//         </NextButton>
// )}
//     >
//       <>
//         {images.map(({ key, src, alt }) => (
//           <GlideSlide key={`project-item-${key}`}>
//             <Image src={src} alt={`Light-up ${alt}`} className="" ml="auto" mr="auto" />
//           </GlideSlide>
//         ))}
//       </>
//     </GlideCarousel>
//   );
// };

const BannerSection = ({
  product,
  allProducts,
  topRow,
  topCol,
  row,
  col,
  titleStyle,
  descriptionStyle,
  textArea,
  imageArea
}) => {
  if (!product || !product.frontmatter) {
    return null;
  }
  const {
    title,
    catalog = [],
    description,
    slug,
    cover,
    manual_pdf,
    slides
  } = product.frontmatter;

  const productCatalog = catalog[0];
  const catalogPath = productCatalog
    ? {
        order: 2,
        title: productCatalog.charAt(0).toUpperCase() + productCatalog.slice(1),
        slug: `/productos/${productCatalog}`
      }
    : {};
  const multiCatalog = catalog.length > 1;
  const singlePath = [
    {
      order: 1,
      title: 'Productos',
      slug: '/productos'
    },
    catalogPath,
    {
      order: 3,
      title,
      slug: `/producto/${slug}`
    }
  ];
  const multiPath = catalog.map((cat, index) => {
    return {
      order: index + 1,
      title: cat.charAt(0).toUpperCase() + cat.slice(1),
      slug: `/productos/${cat}`
    };
  });
  const PATHS = multiCatalog ? multiPath : singlePath;

  const showSlider = slides && slides.length > 0;
  // const SlideImages = slides && slides.map((slide, index) => ({
  //     src: slide,
  //     alt: title,
  //     key: `${title}-${index}`
  //   }));

  const SlideImages =
    slides &&
    slides.map(slide => ({
      original: slide,
      thumbnail: slide,
      originalTitle: title,
      originalAlt: title
      // key: `${title}-${index}`
    }));

  return (
    <ProductBannerWrapper>
      <Container>
        <Box flexBox justifyContent='space-between' {...topRow}>
          <Box {...topCol}>
            <Breadcrumbs paths={PATHS} pills={multiCatalog} />
          </Box>
          <Box {...topCol}>
            <Searchbar
              searchable={allProducts}
              current={product}
              width='100%'
            />
          </Box>
        </Box>

        <Box {...row}>
          <Box {...col} {...textArea}>
            <Heading as='h1' {...titleStyle} content={title} />
            <Text {...descriptionStyle} content={description} />
            <Box>
              <FeatureBlock
                button={
                  <SocialShare node={product} transparent color='textColor' />
                }
              />
            </Box>
          </Box>
          <Box {...col} {...imageArea} className='feature__images'>
            {/* <Image src={cover} alt={title} /> */}
            {/* {showSlider ? <ImageSlider images={SlideImages} /> : <Image src={cover} alt={title} />} */}
            {showSlider ? (
              <ImageGalleryWrapper>
                <ImageGallery
                  items={SlideImages}
                  lazyLoad
                  autoPlay
                  slideInterval={6000}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  // onThumbnailClick={()=>{}}
                />
              </ImageGalleryWrapper>
            ) : (
              <Image
                src={cover}
                alt={title}
                style={{ maxHeight: '80vh', margin: 'auto' }}
              />
            )}
          </Box>
        </Box>

        <Box {...row} className='product__specs'>
          {manual_pdf && (
            <Box {...col} {...textArea}>
              <a href={manual_pdf} target='_blank' rel='noopener noreferrer'>
                <Button
                  icon={<Icon icon={filePdf} size={32} />}
                  iconPosition='left'
                  title='Especificaciones técnicas'
                  variant='textButton'
                  colors='secondary'
                />
              </a>
            </Box>
          )}
        </Box>
      </Container>
    </ProductBannerWrapper>
    // <BannerWrapper id="banner_section">
    //   <Container>
    //     <Box flexBox justifyContent="space-between" {...topRow}>
    //       <Box {...topCol}>
    //         <Breadcrumbs paths={PATHS} />
    //       </Box>
    //       <Box {...topCol}>
    //         <Searchbar searchable={allProducts} current={product} width="100%" />
    //       </Box>
    //     </Box>
    //     <Box className="row" {...row}>
    //       <Box className="col" {...col}>
    //         <FeatureBlock
    //           title={<Heading content={title} {...titleStyle} />}
    //           description={<Text content={description} {...descriptionStyle} />}
    //           button={<SocialShare node={product} transparent color="textColor" />}
    //         />
    //       </Box>
    //       <Box className="col" {...col}>
    //         <FeatureBlock
    //           title={<Heading content={title} {...titleStyle} />}
    //           description={<Text content={description} {...descriptionStyle} />}
    //           button={<SocialShare node={product} transparent color="textColor" />}
    //           className="feature__images"
    //         />
    //       </Box>
    //     </Box>
    //   </Container>
    // </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  topRow: PropTypes.object,
  topCol: PropTypes.object,
  titleStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    // mt: '25px',
    alignItems: 'center'
  },
  col: {
    pr: '15px',
    pl: '15px'
    //   width: ['100%', '100%', '50%', '50%']
  },
  topRow: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  topCol: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '50%', '50%']
  },
  textArea: {
    width: ['100%', '100%', '55%', '50%', '42%']
  },
  imageArea: {
    width: ['100%', '100%', '45%', '50%', '58%'],
    mb: ['40px', '40px', '0', '0', '0'],
    mt: ['40px', '40px', '0', '0', '0']
  },
  titleStyle: {
    fontSize: ['26px', '30px', '30px', '48px', '48px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.30'
  },
  descriptionStyle: {
    fontSize: ['14px', '16px', '18px', '18px', '20px'],
    // fontSize: '16px',
    color: 'textColor',
    // lineHeight: '1.75',
    lineHeight: '30px',
    mb: '30px'
  }
};

export default BannerSection;
